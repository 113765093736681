import DropDownMenu_TextSeparatorsMenuButtonSkinComponent from '../components/DropDownMenu/viewer/skinComps/TextSeparatorsMenuButtonSkin/TextSeparatorsMenuButtonSkin.skin';
import DropDownMenu_TextSeparatorsMenuButtonSkinController from '../components/DropDownMenu/viewer/DropDownMenu.controller';


const DropDownMenu_TextSeparatorsMenuButtonSkin = {
  component: DropDownMenu_TextSeparatorsMenuButtonSkinComponent,
  controller: DropDownMenu_TextSeparatorsMenuButtonSkinController
};


export const components = {
  ['DropDownMenu_TextSeparatorsMenuButtonSkin']: DropDownMenu_TextSeparatorsMenuButtonSkin
};

